<template>
  <t-card>
    <t-card-title>
      <t-icon left>{{ icon }}</t-icon>
      <h2 class="headline">{{ cardTitle }}</h2>
    </t-card-title>
    <t-card-text class="tw-p-4 tw-flex tw-justify-center tw-items-center">
      <v-progress-circular
        color="primary darken-2 ma-4"
        size="80"
        indeterminate
      ></v-progress-circular>
    </t-card-text>
  </t-card>
</template>

<script>
export default {
  name: "loading-state-card",
  props: {
    cardTitle: {
      type: String,
      default: "Loading",
    },
    icon: {
      type: String,
      default: "sync",
    },
  },
};
</script>
