export type Provider = {
  required_fields_for_proposal_submission: string[];
  common_org_name: string;
  legal_org_name: string;
  year_established: string;
  headquarters: string;
  regional_offices: string;
  differentiation: string;
  has_financial_backing: string;
  financial_backing_description: string;
  website_url: string;
  states_served: string[];
  non_us_geographies_served: string;
  project_types_served: string[];
  project_count: number;
  project_mw: number;
  project_mw_in_house: number;
  project_mw_other_epc: number;
  emr: number;
  change_orders: number;
  change_orders_buyer_requested: number;
  change_orders_not_buyer_requested: number;
  industries_served: string[];
  provider_references: string;
  products: string[];
  services: string[];
  primary_service: string;
  proprietary_technologies: string;
  strategic_partners: string;
  ethical_employee_treatment: string;
  dei: string;
  social_responsibility: string;
  underrepresented_owner: boolean;
  environmental_goals_initiatives: string;
  licenses: string[];
  apprenticeship: string;
  logo_url: string;
  updated: Date;
  created: Date;
  [key: string]: any;
};

export type Buyer = {
  common_org_name?: string;
  legal_org_name?: string;
  year_established?: string;
  website_url?: string;
  specific_goals?: string[];
  industry_sector?: string[];
  site_control_type?: string;
  desired_project_type?: string;
  data_room_link?: string;
  esg_strategy?: string;
  updated: Date;
  created: Date;
  [key: string]: any;
};

export type Field = {
  key: string;
  type:
    | "divider"
    | "textfield"
    | "list"
    | "textarea"
    | "numeric"
    | "binary"
    | "image";
  subtype?: "url";
  label?: string;
  short_label?: string;
  question?: string;
  tooltip?: string;
  display: Array<
    | "block"
    | "list"
    | "indent"
    | "show_value"
    | "read_only_hidden"
    | "primary--text"
    | "hide_select_all"
    | "management_mode_only"
  >;
  options?: Array<{ value: any; label: string; icon: string }>;
  constraints?: Array<{
    is_integer?: boolean;
    minimum?: number;
    maximum?: number;
  }>;
  unit?: string;
  mask?: string;
  hint?: string;
  single_select?: boolean;
  extraRules?: ((v: any) => string | boolean)[];
  suggestedValue?: any;
  prerequisite?: string;
};

export type Section = {
  key: string;
  label: string;
  icon: string;
  fields: Array<Field>;
};

export class ForbiddenError extends Error {
  // An attempt to fetch a buyer record when not allowed will prompt this error
}
