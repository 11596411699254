<script setup lang="ts">
const props = defineProps<{
  left?: boolean;
  right?: boolean;
  small?: boolean;
  large?: boolean;
}>();

if (props.left && props.right) {
  console.warn("Either `left` or `right` should be used, not both");
}

if (props.small && props.large) {
  console.warn("Either `small` or `large` should be used, not both");
}
</script>

<style scoped>
.icon {
  /** Adjust these settings as needed */
  font-variation-settings: "FILL" 1, "wght" 400;
}
</style>

<template>
  <span
    class="icon material-symbols-rounded !tw-leading-none tw-text-current tw-inline-flex tw-items-center tw-justify-center tw-align-text-bottom"
    :class="{
      'tw-mr-2': left,
      'tw-ml-2': right,
      'tw-text-base': small,
      'tw-text-4xl': large,
      'tw-text-xl': !(small || large),
    }"
  >
    <slot />
  </span>
</template>
